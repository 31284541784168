<script setup lang="ts">
const route = useRoute()
const appConfig = useAppConfig()
const { isHelpSlideoverOpen } = useDashboard()

const links = [{
  id: 'dashboard',
  label: 'Dashboard',
  icon: 'i-heroicons-home',
  to: '/admin',
  tooltip: {
    text: 'Dashboard',
    shortcuts: ['G', 'D']
  }
},
{
  id: 'addTrade',
  label: 'Add trade',
  icon: 'i-heroicons-plus',
  to: '/admin/add-trade',
  tooltip: {
    text: 'Add trade',
    shortcuts: ['A']
  }
},
{
  id: 'trades',
  label: 'Trades',
  icon: 'i-heroicons-chart-bar',
  to: '/admin/trades',
  tooltip: {
    text: 'Trades',
    shortcuts: ['T']
  }
}
//  {
//   id: 'settings',
//   label: 'Settings',
//   to: '/admin/settings',
//   icon: 'i-heroicons-cog-8-tooth',
//   children: [{
//     label: 'General',
//     to: '/admin/settings',
//     exact: true
//   }, {
//     label: 'Members',
//     to: '/admin/settings/members'
//   }, {
//     label: 'Notifications',
//     to: '/admin/settings/notifications'
//   }],
//   tooltip: {
//     text: 'Settings',
//     shortcuts: ['G', 'S']
//   }
// }
]

const footerLinks = [{
  label: 'Help & Support',
  icon: 'i-heroicons-question-mark-circle',
  click: () => isHelpSlideoverOpen.value = true
}]

const groups = [{
  key: 'links',
  label: 'Go to',
  commands: links.map(link => ({ ...link, shortcuts: link.tooltip?.shortcuts }))
}, {
  key: 'code',
  label: 'Code',
  commands: [{
    id: 'source',
    label: 'View page source',
    icon: 'i-simple-icons-github',
    click: () => {
      window.open(`https://github.com/nuxt-ui-pro/dashboard/blob/main/pages${route.path === '/' ? '/index' : route.path}.vue`, '_blank')
    }
  }]
}]

const defaultColors = ref(['green', 'teal', 'cyan', 'sky', 'blue', 'indigo', 'violet'].map(color => ({ label: color, chip: color, click: () => appConfig.ui.primary = color })))
const colors = computed(() => defaultColors.value.map(color => ({ ...color, active: appConfig.ui.primary === color.label })))
</script>

<template>
  <UDashboardLayout>
    <UDashboardPanel
      :width="250"
      :resizable="{ min: 200, max: 300 }"
      collapsible
    >
      <UDashboardSidebar>
        <template #header>
          <UDashboardSearchButton />
        </template>

        <UDashboardSidebarLinks :links="links" />

        <UDivider />

        <div class="flex-1" />

        <UDashboardSidebarLinks :links="footerLinks" />

        <UDivider class="sticky bottom-0" />

        <template #footer>
          <!-- ~/components/UserDropdown.vue -->
          <UserDropdown />
        </template>
      </UDashboardSidebar>
    </UDashboardPanel>

    <slot />

    <HelpSlideover />
    <NotificationsSlideover />

    <ClientOnly>
      <LazyUDashboardSearch :groups="groups" />
    </ClientOnly>
  </UDashboardLayout>
</template>
